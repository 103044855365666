// Here you can add other styles
.main{
    .container-fluid{
        padding: 0 15px;
    }
}



.breadcrumb{
    margin-bottom: 0;
    border-bottom: none;
}
.sidebar{
    .nav-dropdown-items{
        .nav-link{
            padding:0.6rem .5rem 0.6rem 1.5rem;
            .nav-icon{
                font-size: 0.75rem;
            }
        }
    }

}
.dashBlock{
    a{
        color: #fff;
        text-decoration: none;
        i{
            font-size: 1.5rem;
            -webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
        }
        &:hover{
            text-decoration: none;
            i{
                font-size:1.6rem;
            }
        }
    }
}
.app-footer {
    flex: 0 0 40px;
    font-size: .75rem;
}
.card{
    margin-bottom: 1rem;
}
.table-responsive.customHW{
    margin: -1px;
    width:calc(100% + 2px);
}
.rw-widget-container{
    .rw-widget-input{
        box-shadow: none;
    }
}
.pkgList{
    // .card-header-actions{
    //     margin-top: -.22rem;
    //     margin-bottom: -.22rem;
    // }
    .badge{
        cursor: pointer;
    }
    .pkgSearch{
        max-width: 230px;
        display: inline-flex;
    }
}
.pageSizeOptions{
    position: relative;
    margin-left: 0.5rem;
    .rw-widget{
        float: left;
    }
    .rw-widget-container{
        width: 62px !important;
        height: 28px;
    }
    .pageView{
        float: left;
        margin-left: .5rem;
        text-align: center;
        input{
            text-align: center;
            margin: 0 5px;
            max-width: 40px;
            display: inline;
        }
    }
}
label{
    sup{
        color: #f00;
        font-size: 0.8rem;
        vertical-align: sub;
    }
}
.ql-toolbar {
    border-color:#e4e7ea !important;
    border-radius: .25rem .25rem 0 0;
    .ql-snow {
        border-color:#e4e7ea !important;
    }
}

.ql-container{
    border-color:#e4e7ea !important;
    border-radius:0 0 .25rem .25rem;
    min-height: 150px;
    .ql-editor{
        min-height: 149px;
        border-color:#e4e7ea !important;
    }
    .ql-snow {
        border-color:#e4e7ea !important;
    }
}
#thumbNailImg,#sliderImg{
    max-width: 170px;
}
.thumbnail{
    float: left;
    width:100px;
    height: 100px;
    margin-right: 10px;
    position: relative;
    figure{
        position: relative;
        display: flex;
        width: 100px;
        padding-top: 100%;
        overflow: hidden;
        img{
            position: absolute;
            top:0;
            left: 0;
            right: 0;
            bottom: 0;
            -webkit-justify-content: center;
            justify-content: center;
            display: -webkit-flex;
            display: flex;
            -webkit-align-items: center;
            align-items: center;
            min-height: 100%;
        }
    }
    .delete{
        position: absolute;
        bottom: 10px;
        right: 10px;
        cursor: pointer;
        i{
        background: rgba(255,255,255, 0.7);
        border-radius: 50%;
        padding: 4px 5px;
        }
    }
}
.hotelImg{
    img{
        width: 60px;
        height: 60px;
        margin-right: 10px;
    }
}
#thumbNailImg,
#sliderImg{
    position: relative;
    width: 100px;
    height: 100px;
    text-align: center;
    vertical-align: middle;
    overflow: hidden;
    box-sizing: border-box;
    border: 1px solid #ddd;
    display: inline-block;
    background: url('assets/img/add-img-demo.jpg') no-repeat 0 0;
    cursor: pointer;
}
#thumbNailImg input, 
#sliderImg input{
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 100px;
    opacity: 0;
}
.addImg{
    position: relative;
    width: 60px;
    height: 60px;
    text-align: center;
    vertical-align: middle;
    overflow: hidden;
    box-sizing: border-box;
    border: 1px solid #ddd;
    display: inline-block;
    background: url('assets/img/add-img-demo.jpg') no-repeat 0 0/60px;
    cursor: pointer;
}
.addImg input{
    position: absolute;
    top: 0;
    left: 0;
    width: 60px;
    height: 60px;
    opacity: 0;
}
.cw-65{
    width: 65px;
}
.ql-container.ql-snow {
    border: 1px solid #e4e7ea;
}
.modal-dialog{
    width: 100%;
    max-width: 1020px;
    z-index: 999 !important;
}
.modal-content{
    z-index: 999 !important;
}
table.dx-g-bs4-table{
    margin-bottom: 0.5rem;
    tr.dx-g-bs4-table-invisible-row{
        td{
            padding:0 !important;
        }
    }
    th{
        border-bottom-width: 1px;
        vertical-align: middle;
    }
    td{
        min-width: 100px;
        white-space: nowrap;
        table-layout: auto;
        vertical-align: middle;
        label{
            margin-bottom: 0;
            white-space: nowrap;
        }
        a{
            white-space: nowrap;  
        }
    }
    th.editor{
        min-width: 250px;
    }
}

.cursor-pointer{cursor: pointer;}
.alertBg{
    position: fixed;
    top: 60px;
    left: 230px;
    width:calc(100% - 260px);
    min-height:calc(100vh - 86px);
    background: rgba(255,255,255, 0);
    z-index: 9999;
}
.alert{
    position: fixed;
    top: 120px;
    right: 0;
    z-index: 9999;
}
body.modal-open{
    .table-responsive{
        overflow-x: hidden;
    }
}
.error{
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #f86c6b;
}

button{
    &.btn-link{
    font-size: 0;
    &:before{
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: 1.2rem;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        }
    }
}
a.btn-delete{
    &:before{
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: 1.2rem;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "\F014";
        color:#dc4535;
        }
    }
#edit{
    &.btn-link{
    &:before{
        content: "\F040";
        color:#ffc107;
        }
    }
}
#add{
    &.btn-link{
    &:before{
        content: "\F067";
        color:#007bff;
        }
    }
}
#commit{
    &.btn-link{
    &:before{
        content: "\F00C";
        color:#28a745;
        }
    }
}
#cancel{
    &.btn-link{
    &:before{
        content: "\F00D";
        color:#dc4535;
        }
    }
}
#delete{
    &.btn-link{
    &:before{
        content: "\F014";
        color:#dc4535;
        }
    }
}

a.edit-doc-btn{
    font-size: 0;
    &:before{
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: 1.2rem;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "\F040";
        color:#ffc107;
        margin-left: 0.85rem;
        }
}
.tableBlock{
    position: relative;
    .addRow{
        position: absolute;
        top: 7px;
        left: 50px;
        z-index: 9;
        i{
            font-size: 1.2rem;
            color: #007bff;
        }
    }
}
@media (max-width: 991.98px){
    .app-header {
        .navbar-brand {
        position: absolute;
        top: 0;
        left:20%;
        margin-left:0;
        width: auto;
        }
    }
    .breadcrumb{
        display: none;
    }
}